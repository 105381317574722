















































import { api } from "@/api/api";
import { ApiGetHolidayDto } from "@/api/generated/Api";
import { AuthRole } from "@/auth/auth.constants";
import { authService } from "@/auth/authService";
import VacationScheduleModal from "@/components/administration/vacation-schedule/VacationScheduleModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { VSwitch } from "vuetify/lib";

export default defineComponent({
  name: "VacationSchedulePage",
  components: {
    BaseTooltipIconButton,
    BaseTableFiltered,
    BaseModal,
    VacationScheduleModal,
  },
  setup() {
    const schedules = ref<ApiGetHolidayDto[]>();
    const search = ref<string>("");

    const modalData = ref<ModalBaseData>({
      modalHeadline: "",
      modalType: ModalType.Unspecified,
      showModal: false,
      existingItem: undefined,
      existingItemId: undefined,
    });

    const loadData = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        schedules.value = (await api.holiday.getHolidaysAsync()).data;
      });
    };

    const openNewSchedule = useOpenModal(ModalType.Add, "skolerute", modalData);
    const openDetailsSchedule = useOpenModal(ModalType.Display, "skolerute", modalData);
    const openEditSchedule = useOpenModal(ModalType.Edit, "skolerute", modalData);

    const closeModal = () => {
      modalData.value.showModal = false;
      loadData();
    };

    onMounted(() => {
      loadData();
    });

    return {
      headers,
      filter,
      search,
      modalData,
      schedules,
      openNewSchedule,
      openDetailsSchedule,
      openEditSchedule,
      canUpsert: authService.hasRoles(AuthRole.ModifySchoolRoute),
      closeModal,
    };
  },
});

const headers = [
  { text: "Navn", value: "name" },
  { text: "Aktiv", value: "isActive" },
  { text: "Handlinger", value: "actions" },
];

const filter = [
  {
    component: VSwitch,
    value: "isActive",
    staticClass: "mx-3",
    default: true,
    attrs: {
      label: "Vis inaktive",
      inset: true,
    },
    apply: (value: any, model: any) => model || value,
  },
];
