var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modalData.showModal)?_c('BaseModal',[_c('VacationScheduleModal',{attrs:{"headline":_vm.modalData.modalHeadline,"modalType":_vm.modalData.modalType,"elementId":_vm.modalData.existingItemId,"existingItemModal":_vm.modalData.existingItem},on:{"close":function($event){return _vm.closeModal()}}})],1):_vm._e(),_c('v-form',[_c('BaseTableFiltered',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.schedules,"search":_vm.search,"filter":_vm.filter},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"}},[_vm._v("Skolerute")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk i skoleruter","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.canUpsert)?_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Ny skolerute","icon":"mdi-plus"},on:{"handleClick":_vm.openNewSchedule}}):_vm._e()]},proxy:true},{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Aktiv" : "Inaktiv")+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.openDetailsSchedule(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("vis skolerute")])]),(_vm.canUpsert)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.openEditSchedule(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger skolerute")])]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }